import React from "react"
import PropTypes from "prop-types"
import {
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  MenuPopover,
  MenuLink,
} from "@reach/menu-button";
import "@reach/menu-button/styles.css";

import DropdownJLink from "./Gatsby/DropdownJLink";
var classNames = require("classnames");

function DropdownButton({ heading, links, isCenteredMobile }) {

  if (links.length === 0) {
    return (<div></div>)
  }

  let menuButtonWrapperClasses = classNames("menu-button-wrapper", {
    "has-text-centered-mobile": isCenteredMobile,
    "left-aligned": !isCenteredMobile
  })


  return (
    <div className={menuButtonWrapperClasses} style={{position: "relative"}}>
      <Menu>
        {({ isExpanded }) => {
          let mainClasses = classNames("menu-button", {
            "expanded": isExpanded,
            "is-centered-mobile": isCenteredMobile
          })
          let dropdownClasses = classNames("slide-down", {
          })
          return (
            <>
              <MenuButton className={mainClasses}>
                {heading}
              </MenuButton>
              <MenuList portal={false} className={dropdownClasses}>
                {
                  links.map(link =>
                    <MenuLink key={link.link.title} className="dropdown-link" as={DropdownJLink} to={link.link.title}>
                      {link.link.text}
                    </MenuLink>
                  )
                }
              </MenuList>
            </>
          )
        }}
      </Menu>
    </div>
  );
}

DropdownButton.propTypes = {

}

export default DropdownButton;